import https from "./https";
import store from "@/store";

const merchant = {
  list(params) {
    const organization = store.state.general.organization;

    return https.get("/admin/organizations/" + organization.id + "/merchants", {
      params,
    });
  },
  updateOa(merchant_id) {
    return https.put("/admin/merchants/" + merchant_id + "/line_oas");
  },
  updateOaOnly(merchant_id) {
    return https.put("/admin/merchants/" + merchant_id + "/line-oas-only");
  },
  updateFriends(merchant_id) {
    return https.put("/admin/merchants/" + merchant_id + "/line-friends");
  },
  updateOrgVariables(merchant_id) {
    return https.put("/admin/merchants/" + merchant_id + "/org-variables");
  },
  updateLineWebhook(merchant_id) {
    return https.put("/admin/merchants/" + merchant_id + "/webhooks");
  },
  updateOrCreateLineLiff(merchant_id, force = false) {
    return https.put("/admin/merchants/" + merchant_id + "/liffs", { force });
  },
  updateChannelToken(merchant_id) {
    return https.put("/admin/merchants/" + merchant_id + "/channel-tokens");
  },
  getMerchants(orgId, params) {
    if (!orgId) {
      return Promise.reject("orgId not found.");
    }

    const searchParams = new URLSearchParams(params);

    return https.get(
      `/admin/system-settings/organizations/${orgId}/merchants?${searchParams}`
    );
  },
  getMerchant(orgId, merchantId) {
    if (!orgId) {
      return Promise.reject("orgId not found.");
    }

    if (!merchantId) {
      return Promise.reject("id not found.");
    }

    return https.get(
      `/admin/system-settings/organizations/${orgId}/merchants/${merchantId}`
    );
  },
  createMerchant(orgId, merchant) {
    if (!orgId) {
      return Promise.reject("orgId not found.");
    }

    return https.post(
      `/admin/system-settings/organizations/${orgId}/merchants`,
      merchant
    );
  },
  updateMerchant(orgId, merchantId, merchant) {
    if (!orgId) {
      return Promise.reject("orgId not found.");
    }

    if (!merchantId) {
      return Promise.reject("id not found.");
    }

    return https.put(
      `/admin/system-settings/organizations/${orgId}/merchants/${merchantId}`,
      merchant
    );
  },
  deleteMerchant(orgId, merchantId) {
    if (!orgId) {
      return Promise.reject("orgId not found.");
    }

    if (!merchantId) {
      return Promise.reject("id not found.");
    }

    return https.delete(
      `/admin/system-settings/organizations/${orgId}/merchants/${merchantId}`
    );
  },
};

export default merchant;
