<template>
  <!-- @deprecated 已整合進 CustomerListWithProfile -->
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'CustomerList',
              }"
              >會員管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>@deprecated 會員列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="col-12 col-xl-6 d-flex align-items-center">
        <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">會員列表</h4>
      </div>
      <div class="row my-3">
        <div class="col-12 col-xl-3">
          <b-form-select
            v-model="selectedMerchantID"
            :options="merchants"
            :disabled="isFetchMerchants"
            @change="handleChangeMerchant"
          ></b-form-select>
        </div>
        <div class="col-12 col-xl-3 d-flex align-items-center">
          <b-form-checkbox
            v-model="form.is_binded"
            name="checkbox-1"
            :value="true"
            class="mr-2"
          >
            已綁定
          </b-form-checkbox>
          <b-form-checkbox
            v-model="form.is_binded"
            name="checkbox-1"
            :value="false"
            unchecked-value="not_accepted"
          >
            未綁定
          </b-form-checkbox>
        </div>
        <div
          class="
            col-12 col-xl-6
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            variant="primary"
            :to="{
              name: 'CustomerListImport',
            }"
          >
            顧客會員資料上傳
          </b-button>
          <!-- <input type="file" id="fileInput" hidden />
          <b-button
            class="flex-shrink-0 mb-2 mb-xl-0"
            variant="primary"
            tag="label"
            for="fileInput"
            :disabled="!selectedMerchantID"
            @change="handleUploadFile"
          >
            顧客會員資料上傳</b-button
          > -->
          <b-input-group class="mb-2 mb-xl-0 ml-0 ml-xl-4">
            <b-form-input
              v-model="form.keyword"
              @keyup.enter="getCustomerList"
              placeholder="搜尋姓名、手機 email、平台id(outer_code)"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="getCustomerList"
                ><i class="fa fa-search"></i
              ></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-table
            striped
            hover
            responsive
            :items="customers"
            :fields="fields"
            :busy="isFetchCustomerList"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(picture)="data">
              <div class="d-flex align-items-center justify-content-center">
                <b-avatar
                  :src="data.item.picture"
                  variant="secondary"
                  size="2rem"
                ></b-avatar>
              </div>
            </template>
            <template #cell(name)="data">
              <router-link
                :to="{
                  name: 'CustomerListByUserID',
                  params: {
                    customerID: data.item.id,
                  },
                }"
                >{{ data.item.name }}
              </router-link>
            </template>
            <template #cell(computed_merchant_types)="data">
              <template v-for="source in data.item.computed_merchant_types">
                <b-badge
                  v-if="source"
                  variant="primary"
                  class="mr-1"
                  :key="source"
                  >{{ source }}</b-badge
                >
              </template>
            </template>
            <template #cell(actions)="data">
              <b-dropdown variant="secondary">
                <template slot="button-content">
                  <i class="mdi mdi-menu"></i>
                </template>
                <b-dropdown-item
                  :to="{
                    name: 'CustomerListByUserID',
                    params: { customerID: data.item.id },
                  }"
                >
                  檢視
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BAvatar } from "bootstrap-vue";
import customerApi from "../../../apis/customer";
import merchantApi from "../../../apis/merchant";
import * as consts from '@/consts'
import PermissionChecker from '@/utils/PermissionChecker'

import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
  components: { BAvatar },
  data() {
    return {
      consts: consts,
      isFetchMerchants: false,
      isFetchCustomerList: false,
      selectedMerchantID: null,
      merchants: [{ value: null, text: "請選擇" }],
      form: {
        keyword: null,
        is_binded: false,
      },
      customers: [],
      fields: [
        {
          key: "edit",
          label: "",
        },
        {
          key: "picture",
          label: "",
        },
        {
          key: "name",
          label: "姓名",
        },
        {
          key: "outer_code",
          label: "平台ID",
        },
        {
          key: "mobile_phone",
          label: "手機",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "birth",
          label: "生日",
        },
        {
          key: "computed_merchant_types",
          label: "來源通路",
        },
        {
          key: "actions",
          label: "操作",
        },
      ],
    };
  },

  created() {
    this.getMerchants();

    if (this.selectedMerchantID) {
      this.getCustomerList();
    }
  },

  watch: {
    "form.is_binded"() {
      this.getCustomerList();
    },
  },

  methods: {
    async getMerchants() {
      this.isFetchMerchants = true;
      const { data } = await merchantApi.list();
      this.isFetchMerchants = false;

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: merchant.type_name
              ? `${merchant.name} (${merchant.type_name})`
              : merchant.name,
          };
        }),
      ];
    },
    async getCustomerList() {
      try {
        this.isFetchCustomerList = true;
        const { data } = await customerApi.list(this.selectedMerchantID, {
          is_binded: this.form.is_binded ? 1 : 0,
          keyword: this.form.keyword,
        });
        this.customers = data.data;
      } catch (e) {
        console.log(e);
      }
      this.isFetchCustomerList = false;
    },
    handleChangeMerchant() {
      this.getCustomerList();
    },
    handleBack() {
      this.$router.push({ name: "CustomerList" });
    },
    async handleUploadFile(e) {
      console.log("handleUploadFile", e.target.files);
      try {
        this.isFetchCustomerList = true;
        const { data } = await customerApi.importCustomers(
          this.selectedMerchantID,
          {
            is_binded: this.form.is_binded ? 1 : 0,
            keyword: this.form.keyword,
          }
        );
        this.customers = data.data;
      } catch (e) {
        console.log(e);
      }
    },
    checkPermission (permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
  },
};
</script>

<style></style>
