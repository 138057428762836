var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'CustomerList',
            }}},[_vm._v("會員管理")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("@deprecated 會員列表")])],1)],1)])]),_c('b-card',[_c('div',{staticClass:"col-12 col-xl-6 d-flex align-items-center"},[_c('h4',{staticClass:"mb-2 mb-xl-0 mr-2 font-weight-bold"},[_vm._v("會員列表")])]),_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-12 col-xl-3"},[_c('b-form-select',{attrs:{"options":_vm.merchants,"disabled":_vm.isFetchMerchants},on:{"change":_vm.handleChangeMerchant},model:{value:(_vm.selectedMerchantID),callback:function ($$v) {_vm.selectedMerchantID=$$v},expression:"selectedMerchantID"}})],1),_c('div',{staticClass:"col-12 col-xl-3 d-flex align-items-center"},[_c('b-form-checkbox',{staticClass:"mr-2",attrs:{"name":"checkbox-1","value":true},model:{value:(_vm.form.is_binded),callback:function ($$v) {_vm.$set(_vm.form, "is_binded", $$v)},expression:"form.is_binded"}},[_vm._v(" 已綁定 ")]),_c('b-form-checkbox',{attrs:{"name":"checkbox-1","value":false,"unchecked-value":"not_accepted"},model:{value:(_vm.form.is_binded),callback:function ($$v) {_vm.$set(_vm.form, "is_binded", $$v)},expression:"form.is_binded"}},[_vm._v(" 未綁定 ")])],1),_c('div',{staticClass:"\n          col-12 col-xl-6\n          d-flex\n          flex-column flex-xl-row\n          align-items-start align-items-xl-center\n        "},[_c('b-button',{attrs:{"variant":"primary","to":{
            name: 'CustomerListImport',
          }}},[_vm._v(" 顧客會員資料上傳 ")]),_c('b-input-group',{staticClass:"mb-2 mb-xl-0 ml-0 ml-xl-4"},[_c('b-form-input',{attrs:{"placeholder":"搜尋姓名、手機 email、平台id(outer_code)"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getCustomerList.apply(null, arguments)}},model:{value:(_vm.form.keyword),callback:function ($$v) {_vm.$set(_vm.form, "keyword", $$v)},expression:"form.keyword"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":_vm.getCustomerList}},[_c('i',{staticClass:"fa fa-search"})])],1)],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.customers,"fields":_vm.fields,"busy":_vm.isFetchCustomerList},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(picture)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-avatar',{attrs:{"src":data.item.picture,"variant":"secondary","size":"2rem"}})],1)]}},{key:"cell(name)",fn:function(data){return [_c('router-link',{attrs:{"to":{
                name: 'CustomerListByUserID',
                params: {
                  customerID: data.item.id,
                },
              }}},[_vm._v(_vm._s(data.item.name)+" ")])]}},{key:"cell(computed_merchant_types)",fn:function(data){return [_vm._l((data.item.computed_merchant_types),function(source){return [(source)?_c('b-badge',{key:source,staticClass:"mr-1",attrs:{"variant":"primary"}},[_vm._v(_vm._s(source))]):_vm._e()]})]}},{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{attrs:{"variant":"secondary"}},[_c('template',{slot:"button-content"},[_c('i',{staticClass:"mdi mdi-menu"})]),_c('b-dropdown-item',{attrs:{"to":{
                  name: 'CustomerListByUserID',
                  params: { customerID: data.item.id },
                }}},[_vm._v(" 檢視 ")])],2)]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }